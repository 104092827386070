import React, { useContext, useState } from 'react';
import moment from 'moment';
import { TFunction } from 'i18next';
import { Input, message } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
} from 'antd/lib/table/interface';
import { ITableUsers } from '../../types/ITableUsers';
import UserStatus from '../../enums/userTypes';
import ArrowInbox from '../../icons/ArrowInbox';
import Axios from '../../serverConfig';
import getNotesUrl from '../../consts/getNotesUrl';
import { CirclePhoto } from '../Cards/CardUserData/styles';
import TabsContext from '../../context/tabsContext';
import { ArrowWrapper, BookingButton, Row } from './styles';
import handleRoundPrice from '../../helpers/handleRoundPrice';
import { Avatar } from '../Avatar';
import getTrialUrl from "../../consts/getTrialUrl";

const GetTableColumns = (
  t: TFunction,
  sortedInfo: SorterResult<ITableUsers>,
  filteredInfo: Record<string, FilterValue | null>,
  data: ITableUsers[]
) => {
  const { search } = useLocation();
  const errorMessage = () => message.error('Sorry something going wrong!');
  const successEditMessage = (msg: string) => message.success(`Success! User ${msg}`);

  const { handleActiveTab } = useContext(TabsContext);
  const navigate = useNavigate();

  const [notes, setNotes] = useState({
    userId: 0,
    notes: '',
  });

  const [trial, setTrial] = useState({
    userId: 0,
    trialDays: 0,
  });

  const handleGetNotes = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    userId: number
  ) => setNotes({ userId, notes: event.target.value });

  const handleGetTrial = (
    event: React.ChangeEvent<HTMLInputElement>,
    userId: number
  ) => setTrial({ userId, trialDays: parseInt(event.target.value) });

  const handlePostNote = async () => {
    const body = notes;
    try {
      await Axios.post(getNotesUrl(), body);
      window.location.reload();
      successEditMessage("note added");
    } catch (e) {
      errorMessage();
    }
  };

  const handlePostTrial = async () => {
    const body = trial;
    try {
      // New endpoint needed.
      await Axios.post(
        getTrialUrl(),
        body
      );
      window.location.reload();
      successEditMessage("trial days updated");
    } catch (e) {
      errorMessage();
    }
  };

  const handleRedirectToBookings = (userId: number) => {
    handleActiveTab(2);
    navigate(`/users/${userId}`);
  };

  const columns: ColumnsType<ITableUsers> = [
    {
      title: 'ID',
      dataIndex: 'userId',
      key: 'userId',
      ellipsis: true,
      render: (text, record) => (
        <Link
          to={`/users/${record.userId}${search}`}
          style={{
            borderBottom: '1px solid #3B3558',
          }}
        >
          {text}
        </Link>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input
            placeholder="Search.."
            value={selectedKeys[0]}
            onPressEnter={() => confirm({ closeDropdown: false })}
            onBlur={() => confirm()}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.userId
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filteredValue: filteredInfo.userId || null,
      sorter: (a, b) =>
        a.userId?.toString().localeCompare(b.userId?.toString()),
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.columnKey === 'userId' ? sortedInfo.order : null,
    },
    {
      title: 'PIC',
      dataIndex: 'image',
      key: 'image',
      ellipsis: true,
      render: (url, record) => {
        if (!url || url === 'none') {
          return <CirclePhoto>{record.handle[0].toUpperCase()}</CirclePhoto>;
        }

        return <Avatar filename={url} />;
      },
    },
    {
      title: 'HA',
      dataIndex: 'handle',
      key: 'handle',
      ellipsis: true,
      render: (text, record) => <span>{text}</span>,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <Input
            placeholder="Search.."
            value={selectedKeys[0]}
            onPressEnter={() => confirm({ closeDropdown: false })}
            onBlur={() => confirm()}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.handle
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filteredValue: filteredInfo.handle || null,
      sorter: (a, b) => a.handle.localeCompare(b.handle),
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.columnKey === 'handle' ? sortedInfo.order : null,
    },
    {
      title: 'FN',
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            placeholder="Search.."
            value={selectedKeys[0]}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.firstName
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filteredValue: filteredInfo.firstName || null,
    },
    {
      title: 'LN',
      dataIndex: 'lastName',
      key: 'lastName',
      ellipsis: true,
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.columnKey === 'lastName' ? sortedInfo.order : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            placeholder="Search.."
            value={selectedKeys[0]}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.lastName
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filteredValue: filteredInfo.lastName || null,
    },
    {
      title: 'CO',
      dataIndex: 'company',
      key: 'company',
      ellipsis: true,
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => {
        const x = a.company || '';
        const y = b.company || '';
        return x.localeCompare(y);
      },
      sortOrder: sortedInfo.columnKey === 'company' ? sortedInfo.order : null,
      filters: [{ text: 'No-company', value: 'none' }],
      onFilter: (value, record) =>
        record.company.toLowerCase().includes(value.toString().toLowerCase()),
      filteredValue: filteredInfo.company || null,
      filterMode: 'tree',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            placeholder="Search.."
            value={selectedKeys[0]}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
    },
    {
      title: 'EM',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      render: (text, record) => <span>{text}</span>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            placeholder="Search.."
            value={selectedKeys[0]}
            onPressEnter={() => confirm()}
            onBlur={() => confirm()}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.email
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filteredValue: filteredInfo.email || null,
    },
    {
      title: 'TEL',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: 'LOC',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
      sorter: (a, b) => a.address.localeCompare(b.address),
      sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      ellipsis: true,
      render: (text, record) => (
        <span>{text === 'Invalid date' ? 'none' : text}</span>
      ),
      sorter: (a, b) =>
        moment(a.dateOfBirth, 'YYYY-MM-DD').diff(
          moment(b.dateOfBirth, 'YYYY-MM-DD')
        ),
      sortOrder:
        sortedInfo.columnKey === 'dateOfBirth' ? sortedInfo.order : null,
    },
    {
      title: 'VEH',
      dataIndex: 'totalVehicles',
      key: 'totalVehicles',
      ellipsis: true,
      sorter: (a, b) => a.totalVehicles - b.totalVehicles,
      sortOrder:
        sortedInfo.columnKey === 'totalVehicles' ? sortedInfo.order : null,
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: 'CH',
      dataIndex: 'totalChargers',
      key: 'totalChargers',
      ellipsis: true,
      sorter: (a, b) => a.totalChargers - b.totalChargers,
      sortOrder:
        sortedInfo.columnKey === 'totalChargers' ? sortedInfo.order : null,
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: 'BO',
      dataIndex: 'totalBookings',
      key: 'totalBookings',
      ellipsis: true,
      sorter: (a, b) => a.totalBookings - b.totalBookings,
      sortOrder:
        sortedInfo.columnKey === 'totalBookings' ? sortedInfo.order : null,
      render: (text, record) => (
        <BookingButton onClick={() => handleRedirectToBookings(record.userId)}>
          {text}
        </BookingButton>
      ),
    },
    {
      title: 'RA',
      dataIndex: 'rating',
      key: 'rating',
      sorter: (a, b) =>
        Number(a.rating === 'none' ? 0 : a.rating) -
        Number(b.rating === 'none' ? 0 : b.rating),
      sortOrder: sortedInfo.columnKey === 'rating' ? sortedInfo.order : null,
      render: (text) => (
        <span>{text !== 'none' ? handleRoundPrice(text) : 0}</span>
      ),
    },
    {
      title: 'TA',
      dataIndex: 'tariff',
      key: 'tariff',
      ellipsis: true,
      sorter: (a, b) => a.tariff.localeCompare(b.tariff),
      sortOrder: sortedInfo.columnKey === 'tariff' ? sortedInfo.order : null,
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: 'TD',
      dataIndex: 'trialDays',
      key: 'trialDays',
      ellipsis: true,
      sorter: (a, b) => a.trialDays - b.trialDays,
      sortOrder: sortedInfo.columnKey === 'trialDays' ? sortedInfo.order : null,
      render: (text, record) => (
        <Row>
          <input
            type="number"
            id="trialDays"
            onChange={(e) => handleGetTrial(e, record.userId)}
            style={{ width: '80px' }}
            value={record.userId === trial.userId ? trial.trialDays : text}
          />
          {record.userId === trial.userId && (
            <button type="submit" id={'trial'} onClick={handlePostTrial}>
              Update
            </button>
          )}
        </Row>
      ),
    },
    {
      title: 'ST',
      dataIndex: 'userStatus',
      key: 'userStatus',
      ellipsis: true,
      sorter: (a, b) => a.userStatus.localeCompare(b.userStatus),
      sortOrder:
        sortedInfo.columnKey === 'user_status' ? sortedInfo.order : null,
      filters: [
        { text: 'ACTIVE', value: UserStatus.ACTIVE },
        { text: 'SUSPENDED', value: UserStatus.SUSPENDED },
        { text: 'CLOSED', value: UserStatus.CLOSED },
      ],
      onFilter: (value, record) => record.userStatus.includes(value.toString()),
      filteredValue: filteredInfo.userStatus || null,
      render: (text, record) => {
        return <span>{text[0].toUpperCase() + text.substring(1)}</span>;
      },
    },
    {
      title: 'CODE',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortOrder: sortedInfo.columnKey === 'code' ? sortedInfo.order : null,
      render: (text, record) => <span>{text}</span>,
    },
    {
      width: 240,
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      ellipsis: true,
      render: (text, record) => (
        <Row>
          <textarea
            id="notes"
            onChange={(e) => handleGetNotes(e, record.userId)}
            value={record.userId === notes.userId ? notes.notes : record.notes}
          />
          {record.userId === notes.userId && (
            <button type="submit" id={'note'} onClick={handlePostNote}>
              Update
            </button>
          )}
          <span>
            <ArrowWrapper>
              <ArrowInbox style={{ color: '#3B3558' }} />
            </ArrowWrapper>
          </span>
        </Row>
      ),
    },
  ];
  return columns;
};

export default GetTableColumns;
